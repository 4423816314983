<template>
  <div class="flex justify-center space-x-2 lg:space-x-4">
    <span
      v-for="index in size"
      :key="index"
      class="h-2 w-2 rounded-full lg:h-4 lg:w-4"
      :class="swiper.realIndex === (index - 1) ? 'bg-violet' : 'bg-violet/50'"
      :aria-current="swiper.realIndex === (index - 1)"
    />
  </div>
</template>

<script setup lang="ts">
import {useSwiper} from 'swiper/vue';

defineProps<{
  size: number
}>();

const swiper = useSwiper();
</script>
