<template>
  <button
    class="btn btn-icon"
    @click="swiper.slideNext()"
  >
    <span class="sr-only">Volgende</span>
    <IconsArrowRight class="md:scale-125" />
  </button>
</template>

<script setup>
import {useSwiper} from 'swiper/vue';

const swiper = useSwiper();
</script>
