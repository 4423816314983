<template>
  <div class="container-inset-end flex flex-row items-center gap-4 md:justify-start">
    <span class="text-base md:text-lg">{{ `${swiper.realIndex + 1} / ${size}` }}</span>
    <div class="flex h-3 grow items-center overflow-hidden rounded-full bg-white">
      <div
        :style="{width: `${((swiper.realIndex + 1) / size) * 100}%`}"
        class="h-3 rounded-full bg-violet transition-all duration-700"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {useSwiper} from 'swiper/vue';

defineProps<{
  size: number
}>();

const swiper = useSwiper();
</script>
