<template>
  <button
    class="btn btn-icon"
    @click="swiper.slidePrev()"
  >
    <span class="sr-only">Vorige</span>
    <IconsArrowLeft class="md:scale-125" />
  </button>
</template>

<script setup>
import {useSwiper} from 'swiper/vue';

const swiper = useSwiper();
</script>
